import React, { Fragment, useEffect, useState } from "react"
import styled, { css } from "styled-components"
import { navigate } from "gatsby"
import Parser from "html-react-parser"

import { Typography } from "@material-ui/core"
import { ChevronDown, ConsoleLine } from "mdi-material-ui"

import MenuItems from "./MenuItems"

import { getUrlPath } from "utils/getUrlPath"
import { useStore } from "store"
import * as theme from "theme"
import Link from "components/link"
import LanguageSwitcher from "components/languageSwitcher"

const wpUrl = process.env.GATSBY_WP

const MobileMenu = (props) => {
  const { slug } = props
  const [
    {
      appState: { menu, menuItemActive, submenuItemActive, secondSubmenuItemActive }
    },
    dispatch
  ] = useStore()

  const navigateToPage = (url) => {
    dispatch({ type: "SET_MENU", payload: false })
    if (!url.includes(wpUrl) && url.includes("http")) {
      navigate(url)
    } else {
      navigate(getUrlPath(url))
    }
    dispatch({ type: "SET_MENU_ITEM", payload: null })
    dispatch({ type: "SET_SUB_MENU_ITEM", payload: null })
    dispatch({ type: "SET_SECOND_SUB_MENU_ITEM", payload: null })
  }

  const [isOpen, setIsOpen] = useState(false)

  return (
    <Menu {...props} menuState={menu}>
      <MenuItems name={["main"]}>
        {(items) => {
          return (
            <>
              {/* {process.env.GATSBY_ACTIVE_LANGUAGE === "EN" && (
                <MenuItem green component="div" onClick={() => navigateToPage(`/outdoor/free-diy-project-plans`)}>
                  <span>Free DIY Plans</span>
                </MenuItem>
              )} */}
              {items
                .filter((item) => item.parentDatabaseId === 0 && !item.cssClasses.includes("hidden"))
                .map((item) => {
                  return (
                    <Fragment key={item.id}>
                      <MenuItem
                        zeroChildItems={false}
                        active={item.id === menuItemActive ? true : false}
                        component="div"
                        onClick={() => {
                          item?.childItems?.nodes.length > 0
                            ? dispatch({
                                type: "SET_MENU_ITEM",
                                payload: item.id === menuItemActive ? null : item.id
                              })
                            : navigateToPage(item.url)
                        }}
                      >
                        <span>{item.label.toLowerCase()}</span>
                        {item?.childItems?.nodes.length > 0 && <ChevronDown />}
                      </MenuItem>

                      {item?.childItems?.nodes.length > 0 && (
                        <SubMenu
                          zeroChildItems={!item?.childItems?.nodes.length > 0}
                          active={item.id === menuItemActive ? true : false}
                        >
                          <BackItem
                            zeroChildItems={!item?.childItems?.nodes.length > 0}
                            active={item.id === menuItemActive ? true : false}
                            onClick={() => {
                              item?.childItems?.nodes.length > 0
                                ? dispatch({
                                    type: "SET_MENU_ITEM",
                                    payload: item.id === menuItemActive ? null : item.id
                                  })
                                : navigateToPage(item.url)
                            }}
                          >
                            <ChevronDown />
                            <span>Back</span>
                          </BackItem>
                          {item.childItems.nodes.map((subItem) => {
                            return (
                              <Fragment key={subItem.id}>
                                <SubMenuItem
                                  active={subItem.id === submenuItemActive ? true : false}
                                  component="div"
                                  onClick={() => {
                                    subItem?.childItems?.nodes.length > 0
                                      ? dispatch({
                                          type: "SET_SUB_MENU_ITEM",
                                          payload: subItem.id === submenuItemActive ? null : subItem.id
                                        })
                                      : navigateToPage(subItem.url)
                                  }}
                                >
                                  <span>{subItem.label.toLowerCase()}</span>
                                  {subItem?.childItems?.nodes.length > 0 && <ChevronDown />}
                                </SubMenuItem>

                                {subItem?.childItems?.nodes.length > 0 && (
                                  <SecondSubMenu
                                    zeroChildItems={!item?.childItems?.nodes.length > 0}
                                    active={subItem.id === submenuItemActive ? true : false}
                                  >
                                    <BackItem
                                      zeroChildItems={!item?.childItems?.nodes.length > 0}
                                      active={subItem.id === submenuItemActive ? true : false}
                                      onClick={() => {
                                        subItem?.childItems?.nodes.length > 0
                                          ? dispatch({
                                              type: "SET_SUB_MENU_ITEM",
                                              payload: subItem.id === submenuItemActive ? null : subItem.id
                                            })
                                          : navigateToPage(subItem.url)
                                      }}
                                    >
                                      <ChevronDown />
                                      <span>Back</span>
                                    </BackItem>

                                    {subItem.childItems.nodes.map((secondSubItem, secondSubIndex) => {
                                      return !secondSubItem?.cssClasses?.includes("special-item") ? (
                                        <Fragment key={secondSubItem.id}>
                                          <ScondSubMenuItem
                                            zeroChildItems={!item?.childItems?.nodes.length > 0}
                                            productChild={item.label.toLowerCase() === "products"}
                                            active={secondSubItem.id === secondSubmenuItemActive ? true : false}
                                            component="div"
                                            onClick={() => {
                                              secondSubItem?.childItems?.nodes.length > 0
                                                ? dispatch({
                                                    type: "SET_SECOND_SUB_MENU_ITEM",
                                                    payload:
                                                      secondSubItem.id === secondSubmenuItemActive
                                                        ? null
                                                        : secondSubItem.id
                                                  })
                                                : navigateToPage(secondSubItem.url)
                                            }}
                                          >
                                            <span>{secondSubItem.label.toLowerCase()}</span>
                                            {secondSubItem?.childItems?.nodes.length > 0 && <ChevronDown />}
                                          </ScondSubMenuItem>
                                          <ChildItem
                                            active={secondSubItem.id === secondSubmenuItemActive ? true : false}
                                          >
                                            {secondSubItem.childItems.nodes.map((childItem) => {
                                              return (
                                                <ScondSubMenuItem
                                                  zeroChildItems={true}
                                                  key={childItem.id}
                                                  component="div"
                                                  onClick={() => {
                                                    navigateToPage(childItem.url)
                                                  }}
                                                >
                                                  <span>{childItem.label.toLowerCase()}</span>
                                                </ScondSubMenuItem>
                                              )
                                            })}
                                          </ChildItem>
                                        </Fragment>
                                      ) : (
                                        <Fragment key={secondSubItem.id}>
                                          <HeaderSpecialItem
                                            zeroChildItems={true}
                                            active={true}
                                            component="div"
                                            onClick={() => {
                                              secondSubItem?.childItems?.nodes.length > 0
                                                ? dispatch({
                                                    type: "SET_SECOND_SUB_MENU_ITEM",
                                                    payload:
                                                      secondSubItem.id === secondSubmenuItemActive
                                                        ? null
                                                        : secondSubItem.id
                                                  })
                                                : navigateToPage(secondSubItem.url)
                                            }}
                                          >
                                            <span className="font-bold">{secondSubItem.label.toLowerCase()}</span>
                                          </HeaderSpecialItem>
                                          <ChildSpecialItem>
                                            {secondSubItem.childItems.nodes.map((childItem) => {
                                              return (
                                                <SubMenuItemSpecial
                                                  zeroChildItems={true}
                                                  key={childItem.id}
                                                  component="div"
                                                  onClick={() => {
                                                    navigateToPage(childItem.url)
                                                  }}
                                                >
                                                  <span>{childItem.label.toLowerCase()}</span>
                                                </SubMenuItemSpecial>
                                              )
                                            })}
                                          </ChildSpecialItem>
                                        </Fragment>
                                      )
                                    })}
                                  </SecondSubMenu>
                                )}
                              </Fragment>
                            )
                          })}
                        </SubMenu>
                      )}
                    </Fragment>
                  )
                })}
            </>
          )
        }}
      </MenuItems>
      <MenuItems name={["utility"]}>
        {(items) => {
          return (
            <>
              {items
                .filter((item) => item.parentDatabaseId === 0 && !item.cssClasses.includes("hidden"))
                .map((item) => {
                  return (
                    <Fragment key={item.id}>
                      <MenuItem
                        zeroChildItems={true}
                        active={item.id === menuItemActive ? true : false}
                        component="div"
                        onClick={() => {
                          item?.childItems?.nodes.length > 0
                            ? dispatch({
                                type: "SET_MENU_ITEM",
                                payload: item.id === menuItemActive ? null : item.id
                              })
                            : navigateToPage(item.url)
                        }}
                      >
                        <span>{item.label.toLowerCase()}</span>
                      </MenuItem>
                    </Fragment>
                  )
                })}
            </>
          )
        }}
      </MenuItems>
      <MenuItemForLangSwitch zeroChildItems={true} active={false} component="div">
        <LanguageSwitcher />
      </MenuItemForLangSwitch>
    </Menu>
  )
}

const menuClose = css`
  transform: translateX(120%);
  opacity: 0;
`
const menuOpen = css`
  transform: translateX(0);
  opacity: 1;
`

const Menu = styled.div`
  ${(props) => (!!props.menuState ? menuOpen : menuClose)}
  position: fixed;
  top: 110px;
  height: calc(100% - 64px);
  width: 100%;
  z-index: 10099999;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  background: #dedede;
  overflow-y: scroll;
  overflow-x: hidden;
  left: 0;
`
const MenuItem = styled(Typography)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 68px;
  padding: 0 20px;
  font-size: 16px;
  font-weight: 300;
  text-decoration: none;
  cursor: pointer;
  text-transform: capitalize;
  transition: ease background 0.4s;
  transform: ${(props) => (props.green ? "translateY(2px)" : null)};
  background: ${(props) => (props.zeroChildItems ? "none" : "#FFFFFF")};
  border-bottom: ${(props) => (props.zeroChildItems ? "none" : "1px solid #DEDEDE")};
  color: #000000;
  svg {
    transition: ease all 0.4s;
    transform: rotate(${(props) => (props.active === true ? 90 : 270)}deg);
  }
`
const BackItem = styled(Typography)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 68px;
  padding: 0 20px;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  cursor: pointer;
  transition: ease background 0.4s;
  transform: translateY(2px);
  background: #ffffff;
  border-bottom: 1px solid #dedede;
  color: #000000;
  svg {
    transition: ease all 0.4s;
    transform: rotate(90deg);
  }
`
const SubMenuItem = styled(Typography)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 68px;
  padding: 0 20px;
  font-size: 16px;
  font-weight: 300;
  text-decoration: none;
  cursor: pointer;
  transition: ease background 0.4s;
  text-transform: capitalize;
  transform: ${(props) => (props.green ? "translateY(2px)" : null)};
  background: ${(props) => (props.zeroChildItems ? "none" : "#FFFFFF")};
  border-bottom: ${(props) => (props.zeroChildItems ? "none" : "1px solid #DEDEDE")};
  color: #000000;
  svg {
    transition: ease all 0.4s;
    transform: rotate(${(props) => (props.active === true ? 90 : 270)}deg);
  }
`
const ScondSubMenuItem = styled(Typography)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 68px;
  padding: 0 20px;
  font-size: ${(props) => (props.zeroChildItems || !props.productChild ? "16px" : "12px")};
  font-weight: ${(props) => (props.zeroChildItems || !props.productChild ? "300" : "500")};
  text-decoration: none;
  cursor: pointer;
  transition: ease background 0.4s;
  transform: ${(props) => (props.green ? "translateY(2px)" : null)};
  background: ${(props) => (props.zeroChildItems ? "#dedede" : "#FFFFFF")};
  border-bottom: ${(props) => (props.zeroChildItems ? "none" : "1px solid #DEDEDE")};
  color: ${theme.colors.primary};
  text-transform: ${(props) => (props.zeroChildItems || !props.productChild ? "capitalize" : "uppercase")};

  svg {
    transition: ease all 0.4s;
    transform: rotate(${(props) => (props.active === true ? 0 : -90)}deg);
  }
`

const SubMenu = styled.div`
  background: #dedede;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transform: ${(props) => (props.active === true ? "translateX(0)" : "translateX(+100vw)")};
  z-index: 10;
`

const SecondSubMenu = styled.div`
  background: #dedede;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transform: ${(props) => (props.active === true ? "translateX(0)" : "translateX(+100vw)")};
  z-index: 11;
`

const ChildItem = styled.div`
  width: 100%;
  display: ${(props) => (props.active === true ? "block" : "none")};
  /* padding: 15px 0; */
  background: #dedede;
  position: relative;
  width: 100%;
  span {
    color: black;
  }
`

const ChildSpecialItem = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  flex-wrap: wrap;
  padding: 0 0 0 20px;
  max-width: 360px;
  span {
    color: black;
  }
`
const HeaderSpecialItem = styled(Typography)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 50px;
  padding: 0 20px;
  font-size: 12px;
  font-weight: 500;
  text-decoration: none;
  cursor: pointer;
  transition: ease background 0.4s;
  color: #000000;
  text-transform: capitalize;
`

const SubMenuItemSpecial = styled(Typography)`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 48%;
  height: 30px;
  font-size: 16px;
  font-weight: ${(props) => (props.zeroChildItems ? "300" : "500")};
  text-decoration: none;
  cursor: pointer;
  transition: ease background 0.4s;
  transform: ${(props) => (props.green ? "translateY(2px)" : null)};
  background: ${(props) => (props.zeroChildItems ? "#dedede" : "#FFFFFF")};
  border-bottom: ${(props) => (props.zeroChildItems ? "none" : "1px solid #DEDEDE")};
  color: ${theme.colors.primary};
  /* svg {
    transition: ease all 0.4s;
    transform: rotate(${(props) => (props.active === true ? 0 : -90)}deg);
  } */
`

const MenuItemForLangSwitch = styled.div`
  p {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 68px;
    padding: 0 20px;
    font-size: 16px;
    font-weight: 300;
    text-decoration: none;
    cursor: pointer;
    transition: ease background 0.4s;
    transform: ${(props) => (props.green ? "translateY(2px)" : null)};
    background: ${(props) => (props.zeroChildItems ? "none" : "#FFFFFF")};
    border-bottom: ${(props) => (props.zeroChildItems ? "none" : "1px solid #DEDEDE")};
    color: #000000;
    svg {
      transition: ease all 0.4s;
      transform: rotate(${(props) => (props.active === true ? 90 : 270)}deg);
    }
  }
`
export default MobileMenu
